import qs from 'qs';
import axios from 'axios';
import store from '@/store/index';
import { fileHeader, jsonHeader } from '@/helpers/axiosHeaders';

export default {
    get(path, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_PATH}${path}`, this.axiosConfig(params))
                .then((response) => {
                    if (typeof response.data === 'string' && response.data.indexOf('ParseError') !== -1) {
                        store.dispatch('popups/open', store.state.popups.items.somethingWentWrong, { root: true });
                    } else if (response.data.error > 0) {
                        this.errorHandler(response).then(() => {
                            reject(response.data.result.error);
                        });
                    } else {
                        resolve(response.data.result ? response.data.result : response.data);
                    }
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        reject(response.response.data.errorCode);
                    });
                });
        });
    },
    getBlob(path, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_PATH}${path}`, this.axiosConfigBlob(params))
                .then((response) => {
                    if (typeof response.data === 'string' && response.data.indexOf('ParseError') !== -1) {
                        store.dispatch('popups/open', store.state.popups.items.somethingWentWrong, { root: true });
                    } else if (response.data.error > 0) {
                        this.errorHandler(response).then(() => {
                            reject(response.data.result.error);
                        });
                    } else {
                        resolve(response.data.result ? response.data.result : response.data);
                    }
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        reject(response.response.data ? response.response.data.errorCode : '0');
                    });
                });
        });
    },
    post(path, params, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_API_PATH}${path}`, this.formData(data), this.axiosConfig(params))
                .then((response) => {
                    if (typeof response.data === 'string' && response.data.indexOf('ParseError') !== -1) {
                        store.dispatch('popups/open', store.state.popups.items.somethingWentWrong, { root: true });
                    } else if (response.data.error > 0) {
                        this.errorHandler(response).then(() => {
                            reject(response.data.result.error);
                        });
                    } else {
                        resolve(response.data.result ? response.data.result : response.data);
                    }
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        reject(response.response.data.errorCode);
                    });
                });
        });
    },
    postFile(path, params, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_API_PATH}${path}`, data, this.axiosConfigFile(params))
                .then((response) => {
                    if (typeof response.data === 'string' && response.data.indexOf('ParseError') !== -1) {
                        store.dispatch('popups/open', store.state.popups.items.somethingWentWrong, { root: true });
                    } else if (response.data.error > 0) {
                        this.errorHandler(response).then(() => {
                            reject(response.data.result.error);
                        });
                    } else {
                        resolve(response.data.result ? response.data.result : response.data);
                    }
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        reject(response.response.data.errorCode);
                    });
                });
        });
    },
    put(path, params, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_API_PATH}${path}`, this.formData(data), this.axiosConfig(params))
                .then((response) => {
                    if (typeof response.data === 'string' && response.data.indexOf('ParseError') !== -1) {
                        store.dispatch('popups/open', store.state.popups.items.somethingWentWrong, { root: true });
                    } else if (response.data.error > 0) {
                        this.errorHandler(response).then(() => {
                            reject(response.data.result.error);
                        });
                    } else {
                        resolve(response.data.result ? response.data.result : response.data);
                    }
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        reject(response.response.data.errorCode);
                    });
                });
        });
    },
    delete(path, params) {
        return new Promise((resolve, reject) => {
            axios.delete(`${process.env.VUE_APP_API_PATH}${path}`, this.axiosConfig(params))
                .then((response) => {
                    if (typeof response.data === 'string' && response.data.indexOf('ParseError') !== -1) {
                        store.dispatch('popups/open', store.state.popups.items.somethingWentWrong, { root: true });
                    } else if (response.data.error > 0) {
                        this.errorHandler(response).then(() => {
                            reject(response.data.result.error);
                        });
                    } else {
                        resolve(response.data.result ? response.data.result : response.data);
                    }
                })
                .catch((response) => {
                    this.errorHandler(response).then(() => {
                        reject(response.response.data.errorCode);
                    });
                });
        });
    },
    axiosConfig(params) {
        return {
            withCredentials: true,
            params: params || {},
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            headers: store.state.auth.localStorage.token ? { 'Content-Type': 'application/json;charset=utf-8', Authorization: store.state.auth.localStorage.token } : jsonHeader,
        };
    },
    axiosConfigFile(params) {
        return {
            withCredentials: true,
            params: params || {},
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            headers: { ...fileHeader, Authorization: store.state.auth.localStorage.token },
        };
    },
    axiosConfigBlob(params) {
        return {
            withCredentials: true,
            params: params || {},
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            headers: fileHeader,
            responseType: 'blob',
        };
    },
    formData(data) {
        let form = data;
        if (!(form instanceof FormData)) {
            form = new FormData();
            for (const key in data) {
                form.append(key, data[key]);
            }
        }
        return form;
    },
    errorHandler(response) {
        let error = response.data.error ? response.data.error : 0;
        if (!response.data?.error) {
            switch (response.response.status) {
            case 401:
                error = 405;
                break;
            case 405:
                error = 404;
                break;
            default:
                error = response.response.status;
                break;
            }
        }
        const data = response.data.result;
        return new Promise((resolve) => {
            switch (+error) {
            case 400:// Пользователь не существует
                resolve(response);
                break;
            case 401:// Не верный токен
                store.dispatch('auth/logout', {}, { root: true });
                resolve(response);
                break;
            case 402:// Пользователь заблокирован навсегда
                store.dispatch('auth/logout', {}, { root: true });
                store.commit('auth/setBanPermanent', true, { root: true });
                store.commit('auth/setBanPermanentPhone', data.phone, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                store.commit('auth/setUser', data, { root: true });
                resolve(response);
                break;
            case 403:// Пользователь заблокирован за превышение попыток авторизации
                store.dispatch('auth/clearUserAuthInfo', {}, { root: true });
                store.commit('auth/setBan', true, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                resolve();
                break;
            case 404:// Ресурс не найден
                resolve(response);
                break;
            case 405: // Токен истек
                store.dispatch('auth/checkRefreshToken', {}, { root: true }).then(() => {
                    setTimeout(() => {
                        store.commit('auth/setLoaded', true, { root: true });
                    }, 500);
                    resolve(response);
                });
                break;
            case 406:// Refresh Токен истек
                store.dispatch('auth/clearUserAuthInfo', {}, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                resolve(response);
                break;
            case 407:// Пользователю недосупен раздел
                // store.dispatch('auth/clearUserAuthInfo', {}, {root: true});
                store.commit('auth/setIsNoSuccessSection', true, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                resolve(response);
                break;
            case 408:// У пользователя нет доступа
                store.commit('auth/setIsNoSuccess', true, { root: true });
                store.commit('auth/setLoaded', true, { root: true });
                resolve();
                break;
            case 409:// Не верный пароль
                resolve(response);
                break;
            case 411:// Пользователь заблокирован за превышение попыток получения смс
                store.commit('auth/setLoaded', true, { root: true });
                store.dispatch('auth/clearUserAuthInfo', {}, { root: true });
                store.commit('auth/setSmsBan', data, { root: true });
                resolve(response);
                break;
            default:
                resolve(response);
                store.dispatch('popups/open', store.state.popups.somethingWentWrong, { root: true });
                break;
            }
        });
    },
};
