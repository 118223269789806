<template>
    <!-- v-if="showRoute" -->
    <div class="page"
        :class="{'--edit': (switches.editable.value && isRuleList)
                || (switches.teamEditable.value && isTeamList)
                || switches.contractsEditable.value
                || (switches.contactsEditable.value && isContactsPage),
                '--admin-edit': switches.adminEditable.value}">
        <router-view :isBottomHeader="isBottomHeader || this.showStatusMenu"  v-if="showRoute"/>
        <auth :isTeam="isContractPage" v-else-if="isUserLoaded"></auth>

        <notify-reset v-if="popups.isNotifyReset.show"/>
        <div class="something-went-wrong" v-if="popups.somethingWentWrong.show">
            <div class="df">
                <svg-icon :name="'error'" :width="24" :height="24" class="text-color-red mr4"></svg-icon>
                <div class="text-size-14 text-bold text-color-red">
                    Что-то пошло не так. Обновите страницу
                </div>
            </div>
            <a href="." class="btn btn--ico mt16">
                Обновить страницу
            </a>
        </div>
    </div>
</template>

<style lang="sass">
@import "~@/assets/sass/style.sass"
#app
    margin: 0
</style>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Auth from '@/views/Auth.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import activeCategories from '@/helpers/composables/activeCategories';
import NotifyReset from '@/components/NotifyReset.vue';

export default {
    components: {
        Auth,
        SvgIcon,
        NotifyReset,
    },
    data() {
        return {};
    },
    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    created() {
        this.$router.isReady().then(() => {
            this.getCurrentUser().then(() => {});
        });
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            switches: (state) => state.switches.items,
            isUserLoaded: (state) => state.auth.isLoaded,
            isBanned: (state) => state.auth.isBanned,
            isNoSuccess: (state) => state.auth.isNoSuccess,
            isNoSuccessSection: (state) => state.auth.isNoSuccessSection,
            localStorage: (state) => state.auth.localStorage,
            topMenu: (state) => state.articles.topSections,
            user: (state) => state.auth.user,
        }),

        ...mapGetters({
            needAuth: 'auth/needAuth',
        }),
        showRoute() {
            return !this.needAuth && !this.isBanned && !this.isNoSuccess && !this.isNoSuccessSection && this.isUserLoaded;
        },
        isBottomHeader() {
            return false;
        },
        showStatusMenu() {
            return (!!this.user.chef || !!this.user.tech_writer) && this.isRuleList && this.activeSection.id;
        },
        isRuleList() {
            return this.$route.name === 'rules-home'
                || this.$route.name === 'rules-top'
                || this.$route.name === 'rules'
                || this.$route.name === 'articles-list-status';
        },
        isTeamList() {
            return this.$route.name === 'team-home'
                || this.$route.name === 'team-staff'
                || this.$route.name === 'team-section';
        },
        isContactsPage() {
            return this.$route.name === 'contacts'
                || this.$route.name === 'contact-detail'
                || this.$route.name === 'contacts-add';
        },
        isContractPage() {
            return this.$route.name === 'uploading-contract';
        },
    },
    mounted() {
    },
    methods: {
        ...mapActions('auth', [
            'getCurrentUser',
        ]),
    },
};
</script>
