<template>
    <div class="header">
        <div class="header__top df df-center">
            <div class="text-center text-uppercase">
                <a href="/" class="text-color-gray20 text-size-20 text-bold nohover">БАЗА ЗНАНИЙ ZEMS.PRO</a>
            </div>
        </div>
    </div>
    <div class="container --auth">
        <div class="content">
            <div>
                <div class="auth-block" v-if="!isBanned && !isBannedPermanent && !isNoSuccessSection && !isNoSuccess">
                    <img src="@/assets/img/logo.svg" alt="" style="width: 80px; height: 62px;"/>
                    <div class="text-bold text-size-32 mt16">
                        Добро пожаловать!
                    </div>
                    <div class="text-size-14 mt16">
                      Для входа укажите свой номер телефона
                    </div>
                    <div class="mt24" style="width: 200px">
                        <input-styled
                                  v-model="login.value"
                                  :placeholder="'Номер телефона'"
                                  :disabled="step === 2"
                                  :isEdit="step === 2"
                                  @edit="edit"
                                  name="login"
                                  :placeholderInput="'Номер телефона'"
                                  :is-block-arrow-phone="true"
                                  :is-clear-btn="false"
                                  :error-text="'Введите корректный номер телефона'"
                                  @enter="send()"
                                  :mask="{mask:'+7(999)999-99-99',showMaskOnHover: false}"
                                  :isError="login.error"
                                  :errorCount="errorCount"
                                  :repeatError="login.error"
                                  @focus="removeError('login')"
                                  @input="removeError('login')"
                                  :isErrorIcon="true"
                                  ref="loginInput"
                                  type="tel"
                                  :autoFocus="true"
                                  inputmode="tel"
                        ></input-styled>
                    </div>

                    <div class="mt16" style="width: 200px" v-if="step===2">
                        <input-styled v-model="password.value"
                                      name="password"
                                      :placeholder="'Код из СМС'"
                                      :placeholderInput="'Код из СМС'"
                                      :isError="password.error"
                                      :errorText="''"
                                      @focus="removeError('password')"
                                      @enter="send()"
                                      @input="removeError('password')"
                                      ref="passwordInput"
                                      :isClearBtn="false"
                                      :errorCount="errorCount"
                                      type="tel"
                        ></input-styled>
                    </div>
                    <div class="mt4 df df-center" v-if="step===2">
                        <div class="dif text-center">
                            <div class="text-color-gray50 text-size-12" v-if="timer.smsBan && !password.error">
                                Новый код отправлен на указанный номер.<br>Повторный запрос возможен через {{ timer.timeBan }} ч.
                            </div>
                            <div class="text-size-12" v-else-if="timer.time === 0 && !password.error">
                                 <a @click="newPassword" href="javascript:void(0);" class="link-color hover">Запросить новый</a>
                            </div>
                            <div v-else-if="!password.error" class="text-color-gray50 text-size-12">Запросить новый через {{ timer.time }} сек.</div>
                        </div>
                    </div>

                    <div class="mt32 text-center auth-btn df df-center">
                        <submit-btn tag="button"
                                    @click="send();"
                                    :type="button">
                            <svg-icon :name="'send'" :width="16" :height="16" class="mr4"></svg-icon>
                            продолжить
                        </submit-btn>
                    </div>
                </div>

                <div class="auth-block" v-else-if="isBannedPermanent || isNoSuccess">
                    <svg-icon :name="'close2'" :width="80" :height="80" class="text-color-red"></svg-icon>
                    <div class="mt12 text-size-24 text-color-red text-bold">
                        У номера {{ login.value ? login.value : setBanPermanentPhone }} нет доступа к ZEMS.PRO
                    </div>
                    <div class="mt8 text-size-14 line-height-22 text-center">
                        Если он должен быть, обратитесь к тому, кто пригласил вас на ZEMS.PRO
                    </div>
                </div>

                <div class="auth-block" v-else-if="isNoSuccessSection">
                    <svg-icon :name="'close2'" :width="80" :height="80" class="text-color-red"></svg-icon>
                    <div class="mt12 text-size-24 text-color-red text-bold">
                        У вас нет доступа к этому разделу
                    </div>
                    <div class="mt8 text-size-14 line-height-22 text-center">
                        Если он должен быть, обратитесь к тому, кто пригласил вас на ZEMS.PRO
                    </div>
                    <router-link @click="setIsNoSuccessSection(false);" tag="button" to="/" type="button" class="btn mt24">
                        <svg-icon :name="'send'" :width="16" :height="16" class="mr4"></svg-icon>
                        перейти На главную
                    </router-link>
                </div>

                <div class="auth-block" v-else>
                    <svg-icon :name="'close2'" :width="80" :height="80" class="text-color-red"></svg-icon>
                    <div class="mt12 text-size-24 text-color-red text-bold">
                        Слишком много попыток входа!
                    </div>
                    <div class="mt8 text-size-14 line-height-22 text-center">
                        Вы заблокированы на 12 часов. Если у вас что-то срочное,<br>
                        обратитесь к тому, кто пригласил вас на ZEMS.PRO
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import InputStyled from '@/components/forms/InputStyled.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import SvgIcon from '@/components/SvgIcon.vue';

export default {
    name: 'Auth',
    components: {
        InputStyled,
        SubmitBtn,
        SvgIcon,
    },
    props: {
        isTeam: {
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            login: {
                value: '',
                error: false,
            },
            button: 'normal',
            password: {
                value: '',
                error: false,
            },
            step: 1,
            errorCount: 0,
            // contractUser: '',
        };
    },
    created() {
        this.$router.isReady().then(() => {
            if (this.isContractPage && this.$route.query.id) {
                this.getUserContract({ filter: { contract_url: this.$route.query.id } });
            }
        });
    },
    computed: {
        ...mapState({
            timer: (state) => state.auth.forms.auth.timer,
            isBanned: (state) => state.auth.isBanned,
            isBannedPermanent: (state) => state.auth.isBannedPermanent,
            setBanPermanentPhone: (state) => state.auth.setBanPermanentPhone,
            isNoSuccessSection: (state) => state.auth.isNoSuccessSection,
            isNoSuccess: (state) => state.auth.isNoSuccess,
        }),
    },
    methods: {
        ...mapActions('auth', [
            'checkLogin',
            'getToken',
        ]),
        ...mapActions('staffContract', [
            'getUserContract',
        ]),
        ...mapMutations('auth', [
            'setIsNoSuccessSection',
        ]),
        edit() {
            this.step = 1;
            this.password.error = false;
            this.inputFocus('loginInput');
        },
        removeError(field) {
            this[field].error = false;
        },
        validateLogin() {
            const phone = this.login.value.replace(/[^+\d]/g, '');
            if (phone.length < 12 || phone[2] !== '9') {
                this.login.error = true;
                if (this.login.error && this.errorCount <= 3) {
                    this.errorCount += 1;
                    setTimeout(() => {
                        this.errorCount = 1;
                    }, 700);
                }
                return false;
            }
            if (this.isContractPage && this.login.value !== this.userContract.user.login_to_phone) {
                this.setIsNoSuccessSection(true);
                return false;
            }
            return true;
        },
        validatePassword() {
            if (this.password.value.length < 4) {
                this.password.error = true;
                if (this.password.error && this.errorCount <= 3) {
                    this.errorCount += 1;
                    setTimeout(() => {
                        this.errorCount = 1;
                    }, 700);
                }
                return false;
            }
            return true;
        },
        newPassword() {
            this.password.name = '';
            this.checkLogin({
                login: this.login.value,
                isTeam: this.isTeam ? 'Y' : 'N',
            }).catch((error) => {
                if (+error === 409 || +error === 400) {
                    this.login.error = true;
                }
                if (+error === 410) {
                    this.step = 2;
                }
            });
            this.inputFocus('passwordInput');
        },
        send() {
            switch (this.step) {
            case 1:
                if (this.validateLogin()) {
                    this.checkLogin({
                        login: this.login.value,
                        isTeam: this.isTeam ? 'Y' : 'N',
                    }).then(() => {
                        this.step = 2;
                        this.inputFocus('passwordInput');
                    }).catch((error) => {
                        if (+error === 400) {
                            this.login.error = true;
                        }
                        if (+error === 410) {
                            this.step = 2;
                            this.inputFocus('passwordInput');
                        }
                    });
                }
                break;
            case 2:
                if (this.validateLogin() && this.validatePassword()) {
                    this.getToken({
                        login: this.login.value,
                        password: this.password.value,
                        isTeam: this.isTeam ? 'Y' : 'N',
                    }).catch((error) => {
                        if (+error === 409) {
                            this.password.error = true;
                        }
                    });
                }
                break;
            default:
                break;
            }
        },
        inputFocus(ref) {
            this.$nextTick(() => {
                if (this.$refs[ref]) {
                    this.$refs[ref].$el.getElementsByClassName('inp-s')[0].focus();
                }
            });
        },
    },
};
</script>

<style lang="sass">
.auth-block
    width: 704px
    height: auto
    padding: 48px 0 48px
    background-color: #fff
    border-radius: 4px
    margin: 56px auto 0
    display: flex
    flex-direction: column
    align-items: center
    box-shadow: 0px 0px 6px rgba(148, 181, 225, 0.35)
    .inp-s
        font-weight: bold
.nohover
  &:hover
    color: $gray20
</style>
